<template>
  <section class="page page-tariffs">
    <div class="wrapper">
      <div class="breacrumbs">
        <router-link to="/">Главная</router-link>
        <span class="separator">/</span>
        <span class="current">Тариф middle</span>
      </div>
      <div class="page-title">
        <h1>Общаяя информация по тарифу</h1>
      </div>
      <div>
        <h2>
          Для кого этот тариф?
        </h2>
        <p>
          Данный тариф предназначен для людей, которые хотели бы разобрать любую
          тему с ментором индивидуально. Занятие может включать тестовое
          собеседование с подробным фитбеком.
        </p>

        <h2>
          Как будет проходить обучение?
        </h2>
        <p>
          В рамках данного тарифа создается онлайн митинг, где ментор по заранее
          обговоренной теме проводит онлайн встречу со студентом, где будет по
          материалам ментора будет разбираться та тема, которая была заявлена
          студентом, как требуемая к разъяснению.
        </p>
        <h2>
          Возврат денежных средств.
        </h2>
        <p>
          Каждый студент вправе вернуть деньги, после проведенной онлайн
          встречи, если тема осталась не раскрыта. Высчитывается по формуле:
          полная стоимость курса - 7 процентов. Запрос на возврат денежных
          средств может быть получен не позднее чем 14 дней с момента оплаты.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TariffsPersonal"
};
</script>

<style></style>
